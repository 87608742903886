import { DeepPartial } from 'react-hook-form';

const fixed = {
  apiPrefix: 'api/v2/',
  translation: 'translations/get/',
  reusableContent: 'translations/reusableContent/',
  tareWeightGet: 'tareweight/get/',
  tareWeightGetMulti: 'tareweight/GetMultiple?containerId=',
  eContainer: {
    get: 'econtainer/get/',
    getPdf: 'econtainer/getPdf/',
    getCertificate: 'econtainer/getcertificate/',
    getCertificatePdf: 'econtainer/getCertificatePdf/'
  },
  stickyBarGet: 'stickybar/get',
  schedulesNotifications: {
    emailVerification: 'SchedulesNotifications/ScheduleGenerateToken',
    subscribe: 'SchedulesNotifications/ScheduleNtfSubscribe?token=',
    getEvents: 'SchedulesNotifications/ScheduleNtfGetEvents?token=',
    unsubscribe: 'SchedulesNotifications/ScheduleNtfUnSubscribe?eventValues='
  },
  scheduleByRoute: {
    portInLandsGet: 'scheduleByRoute/GetPortsInLands?query=',
    portInLandsGetV2: 'scheduleByRoute/GetPortsInLandsV2?query=',
    onSearch: 'schedules/point-to-point?',
    getResult: 'scheduleByRoute/getResult',
    getResultNewDesign: 'scheduleByRoute/getResultNewDesign',
    getAdditionalData: 'scheduleByRoute/GetAdditionalData',
    getEzQuoteData: 'scheduleByRoute/GetEzQuote',
    getEzQuoteDataNewDesign: 'scheduleByRoute/GetEzQuoteNewDesign',
    getPortByCode: 'scheduleByRoute/GetPortByCode',
    getPortsByCode: 'scheduleByRoute/GetPortsNewDesign?'
  },
  scheduleByLine: {
    tradesGet: 'scheduleByLine/GetTrades',
    linesGet: 'scheduleByLine/GetLines?tradeCode=',
    onSearch: 'schedules/schedule-by-line?',
    getResult: 'scheduleByLine/GetResult',
    getDetails: 'scheduleByLine/GetDetails',
    getLineCoordinates: 'trade/GetLineCoordinates2?lineId=',
    getAllLines: 'scheduleByLine/GetLinesList'
  },
  scheduleByVessel: {
    vesselsGet: 'scheduleByVessel/GetVessels?query=',
    onSearch: 'schedules/schedule-by-vessel?',
    renderResult: 'scheduleByVessel/RenderResult',
    renderResultV2: 'scheduleByVessel/RenderResultNewDesign',
    externalItems: 'scheduleByVessel/GetExternalServiceItems',
    getTotalVoyages: 'scheduleByVessel/GetTotalVoyages'
  },
  scheduleByPort: {
    portsGet: 'scheduleByPort/GetPortsByCode?',
    onSearch: 'schedules/schedule-by-port?',
    getResult: 'scheduleByPort/GetResult',
    getCountry: 'scheduleByPort/GetCountry?query='
  },
  scheduleContact: {
    getContacts: 'scheduleContact/getContactsData'
  },
  countriesGet: 'form/GetCountry?query=',
  countriesAutocomplete: 'Countries/countriesAutocomplete?query=',
  getAllCountries: 'Countries/Get',
  getPortsByCode: 'ScheduleByPort/GetPortsByCode?countryCode=',
  navbar: {
    getTopMenu: 'navbar/top-menu',
    getTopCountries: 'navbar/countries',
    getMainMenu: 'navbar/main-menu',
    getTopLanguages: 'navbar/languages',
    getTranslations: 'navbar/translations',
    getBranchUrl: 'navbar/contact-branch',
    getCountryContact: 'navbar/country-contacts',
    getCountryCustomers: 'navbar/country-customers',
    getCountryCustomersV2: 'navbar/country-customers-new-ui',
    getMenu: 'navbar/getMenu',
    getNewMenu: 'navbar/getNewMenu',
    userLogins: 'navbar/user-logins',
    /**
     * @deprecated Use ncGetGeoLocation instead.
     */
    getGeoLocation: 'getGeoLocation',
    ncGetGeoLocation: '/nc/getGeoLocation',
    getAutocompleteCountries: 'countries/CountriesAutocomplete',
    getMyZimContact: 'getmycontact'
  },
  storageCertificate: {
    gloabl: 'storageCertificate/getcertificate/',
    india: 'storageCertificate/getindiacertificate/'
  },
  storageCertificateV2: {
    global: 'StorageCertificate/GetMultipleCertificates?containerIds=',
    india: 'StorageCertificate/GetMultipleIndiaCertificates?containerIds='
  },
  trades: 'trade/GetTrades',
  umbracoTrades: 'trade/GetUmbracoTrades',
  expectedVessels: {
    getExpectedVessels: 'expectedVessels/get',
    getExpectedVesselsExcel: 'expectedVessels/getExcelData'
  },
  statusOfVessel: 'statusOfVessels/Get',
  ecoData: {
    getData: 'ecodata/search',
    getPdf: 'ecodata/getPdf',
    getEcoDataText: 'ecodata/GetEcoDataReusableContent'
  },
  careers: {
    get: 'careers',
    descriptionLink: '/about-zim/careers/',
    getIcons: 'careers/careersCategories',
    getEqualOpportunityText: 'careers/equalOpportunity',
    getCareerPromotedSubject: '/careers/promotedSubjectData',
    getOpenJobs: 'careers/careerOpenJobs'
  },
  etaForVessels: {
    get: 'etaForVessels/get',
    getLazy: 'etaForVessels/getLazy'
  },
  phonePrefixGet: 'form/GetPhonePrefixByCountry',
  unsubscribe: {
    sendCode: 'unsubscribe/sendCode',
    checkCode: 'unsubscribe/checkCode',
    getChannel: 'unsubscribe/GetChannel',
    update: 'unsubscribe/Update',
    getSpecificCargo: 'unsubscribe/GetSpecificCargo',
    unsubscribeSpecificCargo: 'unsubscribe/UnsubscribeSpecificCargo',
    subscribeNonDsa: 'unsubscribe/SubscribeNonDsa'
  },
  billOfLading: {
    getCountries: 'billOfLading/getCountries',
    getData: 'billOfLading/getData/',
    getNewData: 'billOfLading/getNewData/'
  },
  demurrage: {
    getCountries: 'demurrage/getCountries?query=',
    getCountry: 'demurrage/getCountries?CountryCode=',
    getPortsByCountry: 'demurrage/getPortsByCountry',
    getFilters: 'demurrage/getFilters',
    getResults: 'demurrage/GetResult',
    getContainerTypes: 'demurrage/GetDemurrageCalculatorContainerTypes',
    getDemurrageCalculatorInfoOSB: 'demurrage/GetDemurrageCalculatorInfoOSB',
    getDemurrageCalculatorInfo: 'demurrage/GetDemurrageCalculatorInfo',
    getDemurrageCalculatorOSBResult: 'demurrage/GetDemurrageCalculatorOSBResult',
    getDemurrageCalculatorResult: 'demurrage/GetDemurrageCalculatorResult',
    getDisputeText: 'demurrage/getDisputeText/',
    getDisputeCountries: 'demurrage/getDisputeCountries',
    getDemurrageContainers: 'demurrage/GetDemurrageContainers',
    createDisputeToken: 'disputeForm/CreateTokenDynamicDispute',
    getInitiators: 'disputeForm/GetInitiator',
    getCategories: 'disputeForm/GetCategory',
    getReasons: 'disputeForm/GetReason'
  },
  demurrageSimulationForm: {
    createToken: 'demurrageSimulationForm/CreateToken',
    getPaymentSources: 'demurrageSimulationForm/GetPaymentSources'
  },
  trackShipment: {
    getTracing: 'trackShipment/GetTracing',
    sendNonDsa: 'trackShipment/NonDsaSubmit',
    getMultiTracing: 'trackShipment/GetMultiTracing',
    onSearch: '/tools/track-a-shipment'
  },
  usStatus: {
    render: 'usstatus/render',
    getContainerDetails: 'usstatus/getContainerDetails',
    getTracingDetails: 'usstatus/getTracingDetails'
  },
  localCharges: {
    getLocalPorts: 'localcharges/GetLocalPorts',
    renderSearch: 'localcharges/RenderSearch'
  },
  usLandTransportationTariff: {
    getInlandUS: 'usLandTransportation/GetInlandUS',
    getPortUS: 'usLandTransportation/GetPortUS',
    renderSearch: 'usLandTransportation/RenderSearch',
    renderResults: 'usLandTransportation/RenderResults',
    renderResultsHistory: 'usLandTransportation/RenderResultsHistory',
    renderResultsMulti: 'usLandTransportation/RenderResultsMulti',
    renderResultsHistoryMulti: 'usLandTransportation/RenderResultsHistoryMulti'
  },
  nonUsLandTransportationTariff: {
    getInland: 'nonUsLandTransportation/GetInland',
    getPort: 'nonUsLandTransportation/GetPort',
    renderSearch: 'nonUsLandTransportation/RenderSearch',
    renderResults: 'nonUsLandTransportation/RenderResults',
    renderResultsMulti: 'nonUsLandTransportation/RenderResultsMulti',
    renderResultsHistory: 'nonUsLandTransportation/RenderResultsHistory',
    renderResultsHistoryMulti: 'nonUsLandTransportation/RenderResultsHistoryMulti'
  },
  captcha: {
    verification: 'captcha/Verification'
  },
  bannerPopup: {
    getBanners: 'GetZimComBanners'
  },
  tariffCalculator: {
    getMarinePorts: 'tariffCalculator/GetMarinePorts',
    getResult: 'tariffCalculator/GetResult',
    getPdf: 'tariffCalculator/GetAsPdf'
  },
  /**
   * @deprecated Use v2 instead.
   */
  news: {
    getNews: 'news',
    getLocalArticles: 'get-local-articles',
    getLocalNews: 'local-news',
    getCountryExtras: 'get-country-extras',
    getCountryUpdates: 'get-country-updates'
  },
  news_v2: {
    getNews: 'api/news',
    getLocalArticles: 'api/get-local-articles',
    getLocalNews: 'api/local-news',
    getCountryExtras: 'api/get-country-extras',
    getCountryUpdates: 'api/get-country-updates'
  },
  /**
   * @deprecated Use v2 instead.
   */
  zimSearch: {
    search: 'zimSearch',
    config: 'zimSearch/config'
  },
  zimSearch_v2: {
    search: 'api/v2/zimSearch',
    config: 'api/v2/zimSearch/config'
  },
  contactUs: {
    countryGeneralContactInfo: 'contactUs/CountryGeneralContactInfo',
    getCountryManagement: 'contactUs/GetCountryManagement',
    getCountryAgencies: 'contactUs/GetCountryAgencies',
    getBranchEmploye: 'contactUs/getBranchEmploye',
    getContactTabs: 'contactUs/GetContactTabs',
    quickContact: 'contactUs/QuickContact',
    getCubes: 'ContactUs/GetCubeFormsData',
    newContacts: 'contact-us/countries',
    getAgencyInfo: 'ContactUs/GetAgencyInfo',
    getCityByContinent: 'ContactUs/GetCityByContinent'
  },
  faq: {
    getFaqData: 'faq/GetFaqData'
  },
  newsletter: {
    subscribe: 'newsletter/SendVerifEmail',
    subscribeEmployeeData: 'newsletter/SendVerifEmailEmployee',
    subscribePersonalData: 'newsletter/SendVerifEmailPersonalData',
    subscribeNewsletter: 'newsletter/Subscribe'
  },
  footer: {
    getFooter: 'footerApi/getFooter/'
  },
  hCaptcha: {
    endPoint: 'https://cn1.hcaptcha.com',
    assetHost: 'https://assets-cn1.hcaptcha.com',
    imgHost: 'https://imgs-cn1.hcaptcha.com',
    reportApi: 'https://reportapi-cn1.hcaptcha.com',
    apiHost: 'https://cn1.hcaptcha.com'
  },
  zimChina: 'www.zimchina.com',
  globalNetwork: {
    getLineCoords: 'trade/GetLineCoordinates2',
    getTradeLinesCoordinates: 'trade/GetTradeLinesCoordinates',
    getPorts: 'globalnetwork/GetPorts',
    getPortsByCountry: 'globalnetwork/GetPortsByCountry',
    initGlobalNetwork: 'globalnetwork/InitGlobalNetworkCached'
  },
  lightRegistration: {
    getMyZimOffices: 'LightRegistration/GetMyZimOffices',
    submit: 'LightRegistration/Submit'
  },
  /**
   * @deprecated Use apim overrides instead.
   */
  apimEndpoints: {
    userLogins: 'Navbar/v1/user-logins',
    getMenu: 'Navbar/v1/getMenu',
    getNewMenu: 'Navbar/v1/getNewMenu',
    zimSearchConfig: 'ZimSearch/v1/config',
    getCurrentCountry: 'CountryCookie/v1/nc/GetCurrentCountry',
    getGeoLocation: 'CountryCookie/v1/nc/GetGeoLocation',
    getBanners: 'GlobalBanners/v1/GetZimComBanners',
    reusableContent: 'Translations/v1/ReusableContent/',
    translations: 'Translations/v1/Get/',
    lightRegistrationMyZimOffices: 'LightRegistration/v1/MyZimOffices',
    lightRegistrationSubmit: 'LightRegistration/v1/Submit',
    getPortsByCountry: 'globalnetwork/GetPortsByCountry',
    initGlobalNetwork: 'globalnetwork/InitGlobalNetworkCached'
  },

  countriesNotCRMGet: 'form/GetNotCRMCountries?query=',
  cargoTracingForm: '/contact-us/cargo-tracing-form',
  customerUpdate: {
    getCombinedModels: '/customerUpdate/combinedModels',
    getCustomerUpdatesPosts: '/customerUpdate/getCustomerUpdatesPosts?',
    getTags: '/customerUpdate/renderTags',
    getRegionsAndCountries: '/customerUpdate/getRegionsAndCountries',
    getLatestNews: 'customerUpdate/getLatestNews'
  },
  mexicoReleaseStatus: '/ReleaseStatusApi/getDataByBLS/'
};

const apim = {
  navbar: {
    userLogins: 'Navbar/v1/user-logins',
    getMenu: 'Navbar/v1/getMenu',
    getNewMenu: 'Navbar/v1/getNewMenu',
    ncGetGeoLocation: 'CountryCookie/v1/nc/GetGeoLocation',
    getAutocompleteCountries: 'Countries/v1/CountriesAutocomplete'
  },
  zimSearch_v2: {
    config: 'ZimSearch/v1/config'
  },
  lightRegistration: {
    getMyZimOffices: 'digitalLightRegistration/v1/MyZimOffices',
    submit: 'digitalLightRegistration/v1/Submit'
  },
  masterData: {
    offices: 'digitalMasterData/Offices/v1',
    additionalTaxInfo: 'digitalMasterData/AdditionalTaxInformation/v1',
    countries: 'digitalMasterData/Countries/v1',
    countriesStates: 'digitalMasterData/CountriesStates/v1'
  },
  bannerPopup: {
    getBanners: 'GlobalBanners/v1/GetZimComBanners'
  },
  reusableContent: 'Translations/v1/ReusableContent/',
  translation: 'Translations/v1/Get/',
  phonePrefixGet: 'Form/v1/GetPhonePrefixByCountry'
} as DeepPartial<typeof fixed>;

export { fixed, apim };
